import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { UsersFacade } from '@app/shared/+state/users';

import { LaravelPagination } from '@app/shared/interfaces';
import { Filter } from '@app/shared/models/filter';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { FilterService } from '@app/shared/services/filter/filter.service';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { Logger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  AccountEditPayload,
  AccountMapping,
  Adjustment,
  AdjustmentClassification,
  AdjustmentsState,
  Agreement,
  AgreementData,
  BulkRecurringTransaction,
  CommissionsState,
  Connection,
  ConnectionState,
  Customer,
  Customization,
  EditableCommission,
  EditableOwner,
  Owner,
  OwnersState,
  PayoutParties,
  PropertiesState,
  Property,
  Recognition,
  RecurringTransaction,
  RecurringTransactionsState,
  Reservation,
  ReservationPreview,
  ReservationState,
  SavedAttachment,
  Session,
  Statement,
  StatementDetail,
  StatementsState,
  StatementStatus,
  StatementTeam,
  Supplier,
  TeamState,
  TeamStatus,
  TrackingCategory,
  Transaction,
  TransactionCategory,
  TransactionsState,
  TransactionType,
  UICommissionAccountSelection,
  UISplitsSelection,
} from '../models/statements.models';
import { UpsellService } from '@app/shared/+state/shared/upsell.service';
const STATEMENTS_STATE_MAP = {
  draft: '_statementsState$',
  published: '_closedStatementsState$',
  in_review: '_inReviewStatementsState$',
};

@Injectable({
  providedIn: 'root',
})
export class StatementsFacade {
  #statementsApiBase = `${environment.apiUrl}/operations/v2`;

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private userPermissionsService: UserPermissionsService,
    private readonly usersFacade: UsersFacade,
    private optimizely: OptimizelyService,
    private csService: InternalCsService,
    private demoService: DemoService,
    private segmentIoService: SegmentIoService,
    private posthog: PosthogService,
    private upsellService: UpsellService
  ) {}

  public ownerStatementEntitlement$ = this.usersFacade.ownerStatementsEntitlement$;
  public showOwnerStatements$ = combineLatest([
    // we're still piloting OS so users with the Optimizely flag are included
    this.optimizely.ownerStatementsEnabled$,
    // plan entitlement for mogul
    this.ownerStatementEntitlement$,
    // user permissions for secondary users
    this.userPermissionsService.hasOwnerStatements$,
  ]).pipe(
    filter(([, , hasOwnerStatementsPermission]) => typeof hasOwnerStatementsPermission === 'boolean'),
    map(
      ([featureFlag, ownerStatementsEntitlement, hasOwnerStatementsPermission]) =>
        featureFlag ||
        (Boolean(ownerStatementsEntitlement?.available) && hasOwnerStatementsPermission) ||
        (this.csService.isCS() && this.demoService.isDemo())
    )
  );

  initialState = {
    data: [],
    loading: false,
    error: null,
  };

  initialOwnersState: OwnersState = { ...this.initialState };
  initialPropertiesState: PropertiesState = { ...this.initialState };
  initialStatementsState: StatementsState = { ...this.initialState };
  initialTransactionsState: TransactionsState = { ...this.initialState };
  initialRecurringTransactionsState: RecurringTransactionsState = { ...this.initialState };
  initialCommissionsState: CommissionsState = { ...this.initialState };
  initialTeamState: TeamState = { data: null, loading: false, error: null };
  initialConnectionState: ConnectionState = { ...this.initialState };
  initialReservationState: ReservationState = { ...this.initialState };
  initialAdjustmentsState: AdjustmentsState = { ...this.initialState, meta: null };

  _ownersState$: BehaviorSubject<OwnersState> = new BehaviorSubject(this.initialOwnersState);
  ownersState$ = this._ownersState$.asObservable();

  _propertiesState$: BehaviorSubject<PropertiesState> = new BehaviorSubject(this.initialPropertiesState);
  propertiesState$ = this._propertiesState$.asObservable();

  _transactionsState$: BehaviorSubject<TransactionsState> = new BehaviorSubject(this.initialTransactionsState);
  transactionsState$ = this._transactionsState$.asObservable();

  _recurringTransactionsState$: BehaviorSubject<RecurringTransactionsState> = new BehaviorSubject(
    this.initialRecurringTransactionsState
  );
  recurringTransactionsState$ = this._recurringTransactionsState$.asObservable();

  _statementsState$: BehaviorSubject<StatementsState> = new BehaviorSubject(this.initialStatementsState);
  openStatementsState$ = this._statementsState$.asObservable();

  _closedStatementsState$: BehaviorSubject<StatementsState> = new BehaviorSubject(this.initialStatementsState);
  closedStatementsState$ = this._closedStatementsState$.asObservable();

  _inReviewStatementsState$: BehaviorSubject<StatementsState> = new BehaviorSubject(this.initialStatementsState);
  inReviewStatementsState$ = this._inReviewStatementsState$.asObservable();

  _commissionsState$: BehaviorSubject<CommissionsState> = new BehaviorSubject(this.initialCommissionsState);
  commissionState$ = this._commissionsState$.asObservable();

  _teamState$: BehaviorSubject<TeamState> = new BehaviorSubject(this.initialTeamState);
  teamState$ = this._teamState$.asObservable();

  _connectionsState$: BehaviorSubject<ConnectionState> = new BehaviorSubject(this.initialConnectionState);
  connectionsState$ = this._connectionsState$.asObservable();

  _reservationsState$: BehaviorSubject<ReservationState> = new BehaviorSubject(this.initialReservationState);
  reservationsState$ = this._reservationsState$.asObservable();

  _adjustmentsState$: BehaviorSubject<AdjustmentsState> = new BehaviorSubject(this.initialAdjustmentsState);
  adjustmentsState$ = this._adjustmentsState$.asObservable();

  onboardingSteps = ['create_owner', 'assign_properties', 'create_commission_rate', 'finished'];

  tranasctionTypes = [
    {
      value: TransactionType.Expense,
      title: 'Expense',
      subtitle: 'Deducted from the total owed to the Owner.',
    },
    {
      value: TransactionType.Credit,
      title: 'Credit',
      subtitle: 'Added to the total owed to the Owner.',
    },
  ];

  public payoutsOptions = [
    {
      value: PayoutParties.Host,
      title: 'My Company',
      subtitle: 'My company receives the reservation payouts, and pays the Owners after deducting commissions.',
    },
    {
      value: PayoutParties.Owner,
      title: 'The Owner',
      subtitle: 'The Owner receives the reservation payouts, and pays my company a commission.',
    },
  ];

  public taxOptions = [
    {
      value: PayoutParties.Host,
      title: 'My Company',
      subtitle: 'My company is responsible for remitting pass-through taxes.',
    },
    {
      value: PayoutParties.Owner,
      title: 'The Owner',
      subtitle: 'The Owner is responsible for remitting pass-through taxes.',
    },
  ];

  public commissionAccountOptions = [
    {
      value: UICommissionAccountSelection.Accommodation,
      title: 'Accommodation after discount',
      subtitle:
        'Commission is charged on the accommodation after discounts are removed. Channel fees do not impact your commission.',
      recommended: true,
    },
    {
      value: UICommissionAccountSelection.Payout,
      title: 'Payout',
      subtitle: 'Commission is charged on the payout minus any pass-through taxes.',
      recommended: false,
    },
    {
      value: UICommissionAccountSelection.Custom,
      title: 'Custom',
      subtitle: 'Define which fees are part of the commission, in addition to accommodation after discounts.',
    },
  ];

  public recognitionOptions = [
    {
      value: Recognition.ProRated,
      title: 'Prorated per night',
      subtitle:
        'Commission revenue is recognized upon the night stayed. Revenue from reservations across multiple months will be recognized in all months stayed.',
    },
    {
      value: Recognition.CheckIn,
      title: 'Check-in date',
      subtitle: 'The full commission revenue is recognized on the check-in date.',
    },
    {
      value: Recognition.CheckOut,
      title: 'Checkout date',
      subtitle: 'The full commission revenue is recognized on the checkout date.',
    },
  ];

  public splitRecognitionOptions = [
    {
      value: Recognition.ProRated,
      title: 'Prorated per night',
      subtitle:
        'Fees are recognized upon the night stayed. Fees from reservations across multiple months will be recognized in all months stayed.',
    },
    {
      value: Recognition.CheckIn,
      title: 'Check-in date',
      subtitle: 'All fees are recognized on the check-in date.',
    },
    {
      value: Recognition.CheckOut,
      title: 'Checkout date',
      subtitle: 'All fees are recognized on the checkout date.',
    },
  ];

  public splitOptions = [
    {
      title: 'Manager keeps all fees',
      value: UISplitsSelection.Host,
      subtitle: 'Manager keeps 100% of all guest fees, and is responsible for paying the host fees.',
    },
    {
      title: 'Owner gets all fees',
      value: UISplitsSelection.Owner,
      subtitle: 'Owner gets 100% of all guest fees, and is responsible for paying the host fees.',
    },
    {
      title: 'Custom',
      value: UISplitsSelection.Custom,
      subtitle: 'Define how fees are split between manager and owner.',
    },
  ];

  updateOnboardingStatus(newStatus: TeamStatus) {
    this.http.post(`${this.#statementsApiBase}/team?onboarding=${newStatus}`, {}).subscribe();

    this._teamState$.next({
      ...this._teamState$.value,
      data: {
        ...this._teamState$.value.data,
        status: newStatus,
      },
    });
  }

  createOwner(owner: Owner): Observable<Owner> {
    const newOwner = { ...owner, properties: this.convertPropertiesToIds(owner.properties) } as EditableOwner;

    return this.http
      .post<Owner>(`${this.#statementsApiBase}/owners`, newOwner)
      .pipe(catchError((error) => this.handleOwnerError(error)));
  }

  updateOwner(owner: Owner, withProperties = false, propertyIds?: number[]): Observable<Owner> {
    const updatedOwner = {
      ...owner,
      properties: propertyIds ?? this.convertPropertiesToIds(owner.properties),
    } as EditableOwner;

    return this.http
      .put<Owner>(`${this.#statementsApiBase}/owners/${owner.id}?onboarding=${withProperties}`, updatedOwner)
      .pipe(catchError((error) => this.handleOwnerError(error)));
  }

  deleteOwner(ownerId: Owner['id']): Observable<any> {
    return this.http
      .delete(`${this.#statementsApiBase}/owners/${ownerId}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Connections
   */
  createAccountingConnection(): Observable<Session> {
    return this.http.post<Session>(`${this.#statementsApiBase}/accounting/create`, {});
  }

  deleteAccountingConnection(id: Connection['id']) {
    return this.http.delete(`${this.#statementsApiBase}/accounting/connections/${id}`);
  }

  listReservations(): void {
    this._reservationsState$.next({
      data: [],
      loading: true,
      error: null,
    });

    this.http
      .get<{ data: Reservation[] }>(`${this.#statementsApiBase}/reservations`)
      .pipe(
        tap((data) => {
          this._reservationsState$.next({
            data: data.data,
            loading: false,
            error: null,
          });
        }),
        catchError((error) => {
          this._reservationsState$.next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching reservations',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  public previewReservation(
    connectionId: Connection['id'],
    reservationUuid: Reservation['uuid'],
    data: { accounts: AccountEditPayload['accounts'] }
  ) {
    return this.http.post<ReservationPreview>(
      `${this.#statementsApiBase}/accounting/${connectionId}/account-mapping/${reservationUuid}/preview`,
      data
    );
  }

  public getCustomizeStatement(propertyId: Property['id']) {
    return this.http
      .get<{ data: Customization[] }>(`${this.#statementsApiBase}/properties/${propertyId}/customizations`)
      .pipe(map((response) => response.data));
  }

  public customizeStatement(
    propertyId: Property['id'],
    statementId: Statement['id'],
    data: { customizations: Customization[] }
  ) {
    return this.http.put<{ customizations: Customization[] }>(
      `${this.#statementsApiBase}/properties/${propertyId}/customize`,
      { ...data, statement_id: statementId }
    );
  }

  listConnections(): void {
    this.upsellService.accountingEnabled$
      .pipe(
        take(1),
        filter(Boolean),
        tap(() =>
          this._connectionsState$.next({
            data: [],
            loading: true,
            error: null,
          })
        ),
        switchMap(() => this.http.get<Connection[]>(`${this.#statementsApiBase}/accounting/connections`)),
        tap((connections) => {
          this._connectionsState$.next({
            data: connections,
            loading: false,
            error: null,
          });
        }),
        catchError((error) => {
          this._connectionsState$.next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching connections',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  /**
   * Get available ledger accounts for a connection
   */
  public listLedgerAccounts(id: number): Observable<AccountMapping> {
    return this.http.get<AccountMapping>(`${this.#statementsApiBase}/accounting/${id}/available-accounts`);
  }

  /**
   * Get available supplier accounts for a connection
   */
  public getSupplierAccounts(id: number): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(`${this.#statementsApiBase}/accounting/${id}/suppliers`);
  }

  public getCustomerAccounts(id: number): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.#statementsApiBase}/accounting/${id}/customers`);
  }

  /**
   * Get available tracking categories for a connection
   */
  public getTrackingCategories(id: number): Observable<TrackingCategory[]> {
    return this.http.get<TrackingCategory[]>(
      `${this.#statementsApiBase}/accounting/${id}/available-tracking-categories`
    );
  }

  /**
   * Get current account mapping configuration for a connection
   */
  public getCurrentAccountMapping(id: number): Observable<any> {
    return this.http.get<any>(`${this.#statementsApiBase}/accounting/${id}/account-mapping`);
  }

  /**
   * Save account mapping configuration for a connection
   */
  public saveAccountingConnection(id: number, data: AccountEditPayload): Observable<any> {
    return this.http.post<any>(`${this.#statementsApiBase}/accounting/${id}/account-mapping`, data);
  }

  private convertPropertiesToIds(properties: Pick<Property, 'id'>[]): number[] {
    if (!properties?.length) {
      return [];
    }
    return properties.map((property) => property.id);
  }

  getTeam() {
    this._teamState$.next({
      data: null,
      loading: true,
      error: null,
    });

    this.http
      .get<StatementTeam>(`${this.#statementsApiBase}/team`)
      .pipe(
        tap((team) => {
          this._teamState$.next({
            data: team,
            loading: false,
            error: null,
          });
        }),
        catchError((error) => {
          this._teamState$.next({
            data: null,
            loading: false,
            error: error.error.message || 'An error occurred fetching Team members',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  onboardTeam() {
    return this.http.post(`${this.#statementsApiBase}/team`, {});
  }

  getStatements(status = StatementStatus.Draft, filters: Filter[] = []) {
    const stateKey = STATEMENTS_STATE_MAP[status] ?? '_statementsState$';

    this[stateKey].next({
      data: [],
      loading: true,
      error: null,
    });

    const apiFilters = this.filterService.transformFilters(filters);

    this.http
      .post<Statement[]>(`${this.#statementsApiBase}/statements?status=${status}`, { filters: apiFilters })
      .pipe(
        tap((statements) => {
          this[stateKey].next({
            data: statements,
            loading: false,
            error: null,
          });
        }),
        startWith({
          data: [],
          loading: true,
          error: null,
        }),
        catchError((error) => {
          this[stateKey].next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching Statements',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  getStatementDetail(id: Statement['id'], params?: Params): Observable<StatementDetail> {
    return this.http
      .get<{ data: StatementDetail }>(`${this.#statementsApiBase}/statements/${id}`, {
        params,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  updateStatementNote(id: Statement['id'], note: string): Observable<StatementDetail> {
    return this.http
      .put<{ data: StatementDetail }>(`${this.#statementsApiBase}/statements/${id}/note`, {
        note,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  downloadStatementPdf(id: number): Observable<Blob> {
    return this.http.get(`${this.#statementsApiBase}/statements/${id}/pdf`, {
      responseType: 'blob',
    });
  }

  downloadStatementPreview(id: number): Observable<Blob> {
    return this.http.get(`${this.#statementsApiBase}/statements/${id}/pdf/preview`, {
      responseType: 'blob',
    });
  }

  public publishStatement(id: Statement['id']): Observable<Statement> {
    return this.http.put<Statement>(`${this.#statementsApiBase}/statements/${id}/publish`, {});
  }

  public unpublishStatement(id: Statement['id']): Observable<Statement> {
    return this.http.put<Statement>(`${this.#statementsApiBase}/statements/${id}/unpublish`, {});
  }

  getOwners(includes: 'external_type'[] = []) {
    this._ownersState$.next({
      data: [],
      loading: true,
      error: null,
    });
    let params = new HttpParams();
    if (includes?.length) {
      includes.forEach((include) => {
        params = params.append(include, 'true');
      });
    }

    this.http
      .get<Owner[]>(`${this.#statementsApiBase}/owners`, { params })
      .pipe(
        map((owners) => {
          this._ownersState$.next({
            data: owners,
            loading: false,
            error: null,
          });
        }),
        catchError((error) => {
          this._ownersState$.next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching Owners',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  getOwner(id: Owner['id']) {
    return this.http.get<Owner>(`${this.#statementsApiBase}/owners/${id}`);
  }

  newOwner(): Owner {
    return {
      type: 'individual',
      name: '',
      firstName: '',
      email: '',
      phone: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      properties: [],
    };
  }

  createCommissionAgreement(commission: AgreementData, propertyIds?: number[]): Observable<unknown> {
    const newAgreement = {
      ...commission,
      properties: propertyIds ?? this.convertPropertiesToIds(commission.properties),
    } as EditableCommission;
    return this.http.post(`${this.#statementsApiBase}/commissions`, newAgreement);
  }

  /**
   * Edit an existing commission agreement.
   * Name is the only required field; ignores any property changes
   * Success returns a 204 No Content
   * @param commission
   */
  public editCommissionAgreement(id: number, commission: AgreementData): Observable<void> {
    const { properties, ...updated } = commission;
    return this.http.put<void>(`${this.#statementsApiBase}/commissions/${id}`, updated);
  }

  public deleteCommissionAgreement(id: Statement['id']): Observable<any> {
    return this.http
      .delete(`${this.#statementsApiBase}/commissions/${id}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  getCommissionAgreements() {
    this._commissionsState$.next({
      data: [],
      loading: true,
      error: null,
    });

    this.http
      .get<Agreement[]>(`${this.#statementsApiBase}/commissions`)
      .pipe(
        tap((commissions) => {
          this._commissionsState$.next({
            data: commissions,
            loading: false,
            error: null,
          });
        }),
        catchError((error) => {
          this._commissionsState$.next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching Commissions',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  getCommissionRate(id: Agreement['id']): Observable<Agreement> {
    return this.http.get<Agreement>(`${this.#statementsApiBase}/commissions/${id}`);
  }

  getProperties() {
    this.http
      .get<Property[]>(`${this.#statementsApiBase}/properties`)
      .pipe(
        tap((properties) => {
          this._propertiesState$.next({
            data: properties,
            loading: false,
            error: null,
          });
        }),
        startWith({
          data: [],
          loading: true,
          error: null,
        }),
        catchError((error) => {
          this._propertiesState$.next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching Properties',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  getPropertiesForSetup() {
    return this.http.get<Property[]>(`${this.#statementsApiBase}/properties?setup=true`);
  }

  getProperty(id: Property['id']): Observable<Property> {
    return this.http.get<Property>(`${this.#statementsApiBase}/properties/${id}`);
  }

  public addPropertyCommissionRate({
    propertyId,
    commissionRateId,
    startsAt,
  }: {
    propertyId: Property['id'];
    commissionRateId: Agreement['id'];
    startsAt: string;
  }): Observable<Property> {
    return this.http.put<Property>(
      `${this.#statementsApiBase}/commissions/${commissionRateId}/properties/${propertyId}`,
      { startsAt }
    );
  }

  public assignPropertiesToCommissionRate({
    commissionRateId,
    properties,
  }: {
    commissionRateId: Agreement['id'];
    properties: number[];
  }) {
    return this.http.put<Property>(`${this.#statementsApiBase}/commissions/${commissionRateId}/properties`, {
      properties,
    });
  }

  public deletePropertyCommissionRate({
    commissionRateId,
    propertyId,
  }: {
    commissionRateId: Agreement['id'];
    propertyId: Property['id'];
  }) {
    return this.http.delete(`${this.#statementsApiBase}/commissions/${commissionRateId}/properties/${propertyId}`);
  }

  public getTransactions(page = 1) {
    this._transactionsState$.next({
      data: [],
      loading: true,
      error: null,
    });

    this.http
      .get<{ data: Transaction[]; meta: LaravelPagination }>(`${this.#statementsApiBase}/transactions?page=${page}`)
      .pipe(
        tap((response) => {
          this._transactionsState$.next({
            data: response.data,
            loading: false,
            error: null,
            meta: response.meta,
          });
        }),
        catchError((error) => {
          this._transactionsState$.next({
            data: [],
            loading: false,
            error: error.error.message || 'An error occurred fetching Transactions',
          });
          return of([]);
        })
      )
      .subscribe();
  }

  public getRecurringTransactions() {
    this._recurringTransactionsState$.next({
      data: [],
      loading: true,
      error: null,
    });

    this.http
      .get<{ data: RecurringTransaction[] }>(`${this.#statementsApiBase}/recurring-transactions`)
      .pipe(
        tap(({ data }) => {
          this._recurringTransactionsState$.next({
            data,
            loading: false,
            error: null,
          });
        })
      )
      .subscribe();
  }

  addTransaction(transaction: Transaction) {
    const formData = this.objToFormData(transaction);

    return this.http.post(`${this.#statementsApiBase}/transactions`, formData);
  }

  addRecurringTransaction(transaction: RecurringTransaction) {
    return this.http.post(`${this.#statementsApiBase}/recurring-transactions`, transaction);
  }

  bulkAddRecurringTransaction(transaction: BulkRecurringTransaction) {
    return this.http.post(`${this.#statementsApiBase}/bulk-recurring-transactions`, transaction);
  }

  updateTransaction(id: Transaction['id'], transaction: Transaction) {
    const updatedTransaction = {
      ...transaction,
      lines: transaction.lines.map((line) => ({ ...line, property: undefined })),
    };
    const formData = this.objToFormData(updatedTransaction);
    // https://github.com/laravel/framework/issues/13457
    formData.append('_method', 'PUT');

    return this.http.post(`${this.#statementsApiBase}/transactions/${id}`, formData);
  }

  updateRecurringTransaction(id: RecurringTransaction['id'], transaction: RecurringTransaction) {
    const updatedTransaction = {
      ...transaction,
      lines: transaction.lines.map((line) => ({ ...line, property: undefined })),
    };

    return this.http.put(`${this.#statementsApiBase}/recurring-transactions/${id}`, updatedTransaction);
  }

  public deleteTransaction(transactionId: Transaction['id']) {
    return this.http.delete(`${this.#statementsApiBase}/transactions/${transactionId}`).pipe(
      tap(() => {
        this.getTransactions();
      }),
      catchError((error) => this.handleError(error))
    );
  }

  public deleteRecurringTransaction(id: RecurringTransaction['id']) {
    return this.http.delete(`${this.#statementsApiBase}/recurring-transactions/${id}`).pipe(
      tap(() => {
        this.getRecurringTransactions();
      }),
      catchError((error) => this.handleError(error))
    );
  }

  public getTransaction(id: Transaction['id']) {
    return this.http.get<Transaction>(`${this.#statementsApiBase}/transactions/${id}`);
  }

  public getRecurringTransaction(id: RecurringTransaction['id']) {
    return this.http.get<RecurringTransaction>(`${this.#statementsApiBase}/recurring-transactions/${id}`);
  }

  public downloadAttachment(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  public deleteAttachment(id: SavedAttachment['id']) {
    return this.http.delete(`${this.#statementsApiBase}/transaction-attachment/${id}`);
  }

  public getAdjustments(filters: Filter[] = [], page = 1): void {
    this._adjustmentsState$.next({ ...this._adjustmentsState$.value, loading: true });
    const apiFilters = this.filterService.transformFilters(filters);
    this.http
      .post<{ data: Adjustment[]; meta: LaravelPagination }>(`${this.#statementsApiBase}/reservation-adjustments`, {
        filters: apiFilters,
        page,
      })
      .pipe(
        tap((adjustments) => {
          this._adjustmentsState$.next({
            data: adjustments.data,
            meta: adjustments.meta,
            loading: false,
            error: null,
          });
        }),
        catchError((error) => {
          this._adjustmentsState$.next({
            ...this._adjustmentsState$.value,
            loading: false,
            error: error.message,
          });
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  public applyAdjustment(
    adjustmentUuid: Adjustment['uuid'],
    account_classification: AdjustmentClassification[],
    transaction_classification: { type: TransactionType; category: TransactionCategory; amount: number }[] = []
  ): Observable<void> {
    return this.http
      .put<void>(`${this.#statementsApiBase}/reservation-adjustments/${adjustmentUuid}`, {
        account_classification,
        transaction_classification,
      })
      .pipe(
        catchError((error) => {
          return throwError(() => ({
            error: true,
            message: error.error?.message || 'Failed to apply adjustment',
          }));
        })
      );
  }

  public openMediaInNewTab(mediaBlob: Blob) {
    const blob = new Blob([mediaBlob], { type: mediaBlob.type });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  private objToFormData(obj) {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        if (value[0] instanceof Blob) {
          value.forEach((blob, blobIndex) => {
            formData.append(`${key}[${blobIndex}]`, blob, blob.name);
          });
        } else if (typeof value[0] !== 'string') {
          value.forEach((item, itemIndex) => {
            Object.keys(item).forEach((itemKey) => {
              formData.append(`${key}[${itemIndex}][${itemKey}]`, item[itemKey] ?? '');
            });
          });
        } else {
          value.forEach((item) => {
            formData.append(key, item);
          });
        }
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }

  private handleError(err) {
    return throwError({
      error: true,
      message: err.error?.message,
    });
  }

  private handleOwnerError(err) {
    // If we get a 422 properties error, we want to trigger a Segment event to show an Intercom banner.
    // We don't want to suppress the error though, as we can't rely or know that Intercom is configured to _do_ anything
    // The intercom banner should sit above our own error banner anyway
    if (err.status === 422 && err.error?.errors?.properties) {
      Logger.info('Owner statements property limit reached, triggering Segment event');
      this.segmentIoService.track(SegmentEvent.OwnerStatementsPropertyLimitReached, {});
    }

    return throwError({
      error: true,
      message: err.error?.message,
    });
  }
}
